// extracted by mini-css-extract-plugin
export var bottomHeading = "index-module--bottomHeading--SK2vf";
export var contentBlock = "index-module--contentBlock--N0XxT";
export var copy = "index-module--copy--0bHi1";
export var ctaAlt = "index-module--ctaAlt---1Nso";
export var flareWrapper = "index-module--flareWrapper--hHy1M";
export var headerContent = "index-module--headerContent--bBpno";
export var heroButtons = "index-module--heroButtons--joVxQ";
export var heroHeading = "index-module--heroHeading--2EoHw";
export var heroIllustration = "index-module--heroIllustration--cd6tf";
export var heroIllustrationMobile = "index-module--heroIllustrationMobile--g46VN";
export var heroSubtext = "index-module--heroSubtext--1z7Tl";
export var image = "index-module--image--GjHU4";
export var imagery = "index-module--imagery--c0Aws";
export var innerContainer = "index-module--innerContainer--e1cVp";
export var inverse = "index-module--inverse--ft7mC";
export var transitionStrip = "index-module--transitionStrip--OTJdb";
export var videoContainer = "index-module--videoContainer--03CbD";