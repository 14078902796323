import * as React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import Button from "../components/button"
import MainMenu from "../components/mainMenu"
import HeroBanner from "../components/heroBanner"
import Video from "../components/video"
import Footer from "../components/footer"
//import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import ContentContainer from '../components/contentContainer';

import assetPlaceholderImage from "../assets/images/placeholder-1.jpg";
import assetPlaceholderImageHelping from "../assets/images/helping-illustration.svg";
import * as styles from "./styles/index.module.css";

import assetImageProduct from "../assets/images/product-illustration.svg";
import assetHeroIllustration from "../assets/images/hero-illustration.svg";
import assetFlare1 from "../assets/images/bg-flare-turner.svg";

import assetMainVideo from "../assets/videos/KitStart_Homepage_Video.mp4";
import assetMainVideoPoster from "../assets/videos/KitStart_Homepage_Video_Placeholder.jpg";


class IndexPage extends React.Component {

  constructor(props) {

    super(props);
    //this.tawkMessengerRef = React.createRef();
    //<TawkMessengerReact propertyId="62b2d7927b967b117995e78c" widgetId="1g659k5j7" ref={this.tawkMessengerRef} />
  }

  render() {

    return (
      <Layout>
        <Helmet title="Homepage" defer={false}>
        </Helmet>
        <MainMenu pathname={this.props.location.pathname}></MainMenu>
        <ContentContainer>
          <div className={`${styles.headerContent}`}>
            <div className={`${styles.innerContainer}`}>
              <h1 className={`${styles.heroHeading}`}>An essential kitchenware kit delivered to you.</h1>
              <div className={` ${styles.heroSubtext}`}>Everything in one box without visiting another store. Every sale contributes to kitchenware for those in need.</div>
              <div className={`${styles.heroIllustrationMobile}`}>
                <img src={assetHeroIllustration} />
              </div>
              <div className={`${styles.heroButtons}`}>
                <Button variant="primary" to="/products">View The Kit</Button>
              </div>
            </div>
            <div className={`${styles.heroIllustration}`}>
              <img src={assetHeroIllustration} />
            </div>
          </div>
        </ContentContainer>
        <div className={`${styles.videoContainer}`}>
          <div className={`${styles.flareWrapper}`}>
            <Video src={assetMainVideo} poster={assetMainVideoPoster} />
            <img src={assetFlare1} />
          </div>
        </div>
        <div className={`${styles.mainContent}`}>
          <ContentContainer>
            <div className={`${styles.contentBlock}`}>
              <div className={`${styles.copy}`}>
                <h2 className={`bottomGutter`}>The perfect starting point.</h2>
                <p className={`subtitle`}>From leading brands.</p>
                <p>Whether leaving the nest, starting the next chapter as a couple, or gifting to someone that is - KitStart takes the stress away from setting up a new kitchen. </p>
                <p>Traditional cookware sets don't include utensils or what you need on day one. Get set up with the essentials and save your energy for all the rest of the tasks on your plate.</p>
              </div>
              <div className={`${styles.imagery}`}>
                <img src={assetImageProduct} className={`${styles.image}`} />
              </div>
            </div>
            <div className={`${styles.contentBlock} ${styles.inverse}`}>
              <div className={`${styles.copy}`}>
                <h2 className={`bottomGutter`}>Half of all profits help those in need.</h2>
                <p>Your purchase helps provide essential kitchenware to people displaced due to natural disaster, domestic violence and more. </p>
              </div>
              <div className={`${styles.imagery}`}>
                <img src={assetPlaceholderImageHelping} className={`${styles.image}`} />
              </div>
            </div>
          </ContentContainer>
          <HeroBanner>
            <h2 className={`${styles.bottomHeading}`}>See What's Inside</h2>
            <p>Includes essential cookware, ovenware, utensils, and more.</p>
            <Button variant="primaryInverse" to="/products">View The Kit</Button>
          </HeroBanner>
        </div>
        <Footer />
      </Layout>
    )
  }
}

export default IndexPage 